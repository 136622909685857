import {
  Box,
  Button,
  Card,
  CardContent,
  Slider,
  Typography,
} from "@mui/material";
import { useState } from "react";

const Splash = ({ onGeneratedGrid }) => {
  const [gridDimension, setGridDimension] = useState(10);
  const handleSliderChange = (e) => {
    setGridDimension(Number(e.target.value));
  };
  const handleClickGeneration = () => {
    return onGeneratedGrid(gridDimension);
  };
  return (
    <Box
      justifyContent="center"
      display="flex"
      alignItems="center"
      sx={{ height: "100%" }}
    >
      <Card style={{ width: "300px", height: "300px" }}>
        <CardContent>
          <Box
            padding={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography variant="h5">Grid Generator</Typography>
            <Typography variant="body1">
              How many columns and rows should the map have?
            </Typography>
            <Typography variant="body1">Default: 10</Typography>
            <br></br>
            <Slider
              color="secondary"
              defaultValue={10}
              max={20}
              marks
              min={1}
              valueLabelDisplay="auto"
              onChange={handleSliderChange}
            ></Slider>
            <br></br>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleClickGeneration}
            >
              Generate
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Splash;
