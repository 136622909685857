import React, { useRef, useState } from "react";
import Splash from "./components/Splash";
import Gridder from "./components/Gridder/Gridder";
import { Grid, Slide } from "@mui/material";

const App = () => {
  const [grid, setGrid] = useState(null);
  const [generate, setGenerateClick] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const containerRef = useRef();

  const handleGeneration = (gridDimension) => {
    setGenerateClick(true);
    setTimeout(() => setShowGrid(true), 400);
    setGrid(gridDimension);
  };

  return (
    <Grid
      ref={containerRef}
      sx={{ height: "100%", overflow: "hidden" }}
      container
      alignContent="center"
      alignItems="center"
      justifyContent="center"
    >
      <Slide
        timeout={500}
        container={containerRef.current}
        mountOnEnter
        unmountOnExit
        direction="left"
        in={!generate}
      >
        <div>
          <Splash onGeneratedGrid={handleGeneration} />
        </div>
      </Slide>
      <Slide
        timeout={500}
        container={containerRef.current}
        mountOnEnter
        unmountOnExit
        direction="right"
        in={showGrid}
      >
        <div>{grid && <Gridder gridDimension={grid} />}</div>
      </Slide>
    </Grid>
  );
};

export default App;
