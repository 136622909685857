import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import tilesData from "../../assets/tiles/tilesData.json";

const TilesSelector = ({ onSelected, onDisabledBorder, onExport }) => {
  const [selectedTile, setSelectedTile] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const categories = tilesData.map((category) => ({
    category: category.category,
    tiles: category.tiles.map((tile) => tile),
  }));

  const handleExport = () => {
    onExport(true);
  };

  const handleSelect = (src, file) => {
    onSelected({ src, file });
    setSelectedTile(src);
  };

  const handleDisabledBorder = (event) => {
    onDisabledBorder(event.target.checked);
  };

  const handleCategoryChange = (event) => {
    const newCategory = event.target.value;
    setSelectedCategory(newCategory);
    setCurrentPage(1);
  };

  let filteredCategory = null;
  if (selectedCategory !== null) {
    filteredCategory = categories.find(
      (category) => category.category === selectedCategory
    );
  }

  const totalTilesCount = categories.reduce(
    (total, category) => total + category.tiles.length,
    0
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Grid
        container
        display="flex"
        direction="column"
        width="500px"
        justifyContent="space-around"
      >
        <Typography sx={{ textAlign: "center" }} variant="body1">
          Select a tile from below
          <br />
          Use [Mouse Left Click] to place
          <br />
          Use [Mouse Right Click] to delete
        </Typography>
        <br />
        <Grid item alignSelf="center">
          <FormControlLabel
            sx={{ userSelect: "none" }}
            control={<Checkbox onChange={handleDisabledBorder} />}
            label="Disable Grid Border"
          />
        </Grid>
        <br />
        <FormControl sx={{ alignItems: "center" }} variant="outlined">
          <Select
            sx={{ width: "200px" }}
            displayEmpty
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            <MenuItem value={""}>All</MenuItem>
            {categories.map((category, index) => (
              <MenuItem key={index} value={category.category}>
                {category.category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        <Grid container spacing={1} justifyContent="center">
          {filteredCategory !== null || selectedCategory === null ? (
            <>
              {filteredCategory
                ? filteredCategory.tiles
                    .slice((currentPage - 1) * 8, currentPage * 8)
                    .map((tile, tileIndex) => {
                      return (
                        <Grid
                          key={`tile-${tileIndex}`}
                          item
                          onClick={() => handleSelect(tile.src, tile.file)}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={tile.src}
                            alt={tile.file}
                            style={{
                              width: "40px",
                              height: "40px",
                              outline:
                                selectedTile === tile.src
                                  ? "5px solid red"
                                  : "",
                            }}
                          />
                        </Grid>
                      );
                    })
                : categories
                    .flatMap((category) => category.tiles)
                    .slice((currentPage - 1) * 8, currentPage * 8)
                    .map((tile, tileIndex) => (
                      <Grid
                        key={`tile-${tileIndex}`}
                        item
                        onClick={() => handleSelect(tile.src, tile.file)}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={tile.src}
                          alt={tile.file}
                          style={{
                            width: "40px",
                            height: "40px",
                            outline:
                              selectedTile === tile.src ? "5px solid red" : "",
                          }}
                        />
                      </Grid>
                    ))}
            </>
          ) : (
            <Typography>
              No tiles available for the selected category.
            </Typography>
          )}
        </Grid>
      </Grid>
      <br />
      <Pagination
        count={Math.ceil(
          (filteredCategory ? filteredCategory.tiles.length : totalTilesCount) /
            8
        )}
        page={currentPage}
        onChange={(event, value) => setCurrentPage(value)}
      />
      <br />
      <Button variant="outlined" onClick={handleExport}>
        export json
      </Button>
    </Grid>
  );
};

export default TilesSelector;
