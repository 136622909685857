import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

const GridGenerator = ({
  gridDimension,
  selectedTile,
  disableBorder,
  exporting,
  resetExport,
}) => {
  const [gridState, setGridState] = useState(
    Array.from({ length: gridDimension }, () =>
      new Array(gridDimension ? gridDimension : 10).fill(null)
    )
  );
  const [isDragging, setIsDragging] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  useEffect(() => {
    const handleMouseUp = () => {
      setIsDragging(false);
    };
    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
  });

  useEffect(() => {
    if (exporting) {
      const jsonContent = JSON.stringify(gridState, null, 2);
      const blob = new Blob([jsonContent], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "map.json";
      a.click();
      URL.revokeObjectURL(url);
      resetExport(false);
    }
  }, [exporting, gridState, resetExport]);

  const handleCellClick = (row, col) => {
    if (selectedTile) {
      const newGridState = gridState.map((rowArr, rowIndex) =>
        row === rowIndex
          ? [...rowArr.slice(0, col), selectedTile, ...rowArr.slice(col + 1)]
          : rowArr
      );
      setGridState(newGridState);
    }
  };

  const handleCellDrag = (row, col) => {
    if (isDragging && selectedTile) {
      const newGridState = gridState.map((rowArr, rowIndex) =>
        row === rowIndex
          ? [...rowArr.slice(0, col), selectedTile, ...rowArr.slice(col + 1)]
          : rowArr
      );
      setGridState(newGridState);
    } else if (isRemoving) {
      const newGridState = gridState.map((rowArr, rowIndex) =>
        row === rowIndex
          ? rowArr.map((cell, cellIndex) => (col === cellIndex ? null : cell))
          : rowArr
      );
      setGridState(newGridState);
    }
  };
  return (
    <Grid container alignContent="center" sx={{ userSelect: "none" }}>
      {gridState.map((rowItems, row) => (
        <Grid key={`row-${row}`} container justifyContent="center">
          {rowItems.map((cell, col) => (
            <Grid
              key={`cell-${row}-${col}`}
              item
              onClick={() => handleCellClick(row, col)}
              onMouseEnter={() => handleCellDrag(row, col)}
              onMouseDown={(event) => {
                if (event.button === 2) {
                  setIsRemoving(true);
                } else {
                  setIsDragging(true);
                  handleCellClick(row, col);
                }
              }}
              onMouseUp={() => {
                setIsDragging(false);
                setIsRemoving(false);
              }}
              onContextMenu={(event) => {
                event.preventDefault();
              }}
              sx={{
                width: `calc(700px / ${gridDimension})`,
                height: `calc(700px / ${gridDimension})`,
                border: !disableBorder
                  ? "1px solid rgba(205, 150, 255, .35)"
                  : "none",
                borderRadius: "0px",
                position: "relative",
              }}
            >
              {cell ? (
                <img
                  src={cell.src}
                  draggable={false}
                  alt={`Tile ${row}-${col}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <Typography sx={{ fontSize: "8px" }}>
                  &nbsp;{`${+row} - ${col}`}
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

export default GridGenerator;
