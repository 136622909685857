import { Box } from "@mui/material";
import TilesSelector from "./TilesSelector";
import GridGenerator from "./GridGenerator";
import { useState } from "react";

const Gridder = ({ gridDimension }) => {
  const [selectedTile, setSelectedTile] = useState(null);
  const [disableBorder, setDisableBorder] = useState(false);
  const [exporting, setExport] = useState(false);

  return (
    <Box
      sx={{ height: "100%" }}
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      <GridGenerator
        gridDimension={gridDimension}
        selectedTile={selectedTile}
        disableBorder={disableBorder}
        exporting={exporting}
        resetExport={setExport}
      />
      <TilesSelector
        onSelected={setSelectedTile}
        onDisabledBorder={setDisableBorder}
        onExport={setExport}
      />
    </Box>
  );
};

export default Gridder;
